<template>
  <layout name="Default">
    <div class="Performance">
      <authorize-routed-with-fallback :route="$route">
        <router-view />
      </authorize-routed-with-fallback>
    </div>
  </layout>
</template>

<script>
import AuthorizeRoutedWithFallback from '@/components/core/AuthorizeRoutedWithFallback'
import { mapActions } from 'vuex'

export default {
  components: {
    AuthorizeRoutedWithFallback
  },
  async created () {
    // Check if the start and end are not set, and if they are not, we set them
    this.setDefaultFiltersTimeFiltersIfNeeded()
  },
  methods: {
    ...mapActions('globalFilters', ['setDefaultFiltersTimeFiltersIfNeeded'])
  }
}
</script>
